@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
.Lineups-loading {
  text-align: center;
  padding: 100px; }


.LineupGenerator {
  height: 1500px;
  border-radius: 10px; }
  .LineupGenerator-menu {
    border-bottom: 2px solid black; }
    .LineupGenerator-menu-item {
      display: inline-block;
      margin-right: 4px;
      cursor: pointer;
      padding: 6px;
      background-color: #AF5E5E;
      color: white; }

body {
  font-family: 'Red Hat Display', sans-serif !important;
  background-color: #1D1F23 !important;
  color: #fff !important;
}

